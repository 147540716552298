import { Button } from "@mui/material";
import { Theme } from "@emotion/react";
import { formGap, inlineTextIconMargin, modalPadding, styled, Body2, Body3 } from "@iventis/styles";
import React, { useState } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadFileInput } from "./upload-file-input";

type errorTypes = "file_size_exceeded" | "server_error";
export interface FileUploadComponentProps extends Omit<FileUploadPreviewComponentProps, "removeRequested" | "removeImageText"> {
    PreviewComponent?: React.FC<FileUploadPreviewComponentProps>;
    previewImageClassName?: string;
    className?: string;
    uploadFile: (file: File) => void;
    uploadButtonText: string;
    inputAccept?: string;
    persistFileSelectInput?: boolean;
    ariaLabelledBy?: string;
    /** File max size in Mb */
    maxFileSize?: number;
    onFileUploadTooLarge?: () => void;
    disabled?: boolean;
    removeRequested?: FileUploadPreviewComponentProps["removeRequested"];
    removeImageText?: FileUploadPreviewComponentProps["removeImageText"];
    dataTestIdInput?: string;
    dataTestIdButton?: string;
    showDeleteButtonOnFileUploaded?: boolean;
    fileTooLarge?: boolean;
    showText?: boolean;
    topText?: string;
    fileExtensions?: string[];
    externalErrorMessage?: string;
}

export const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
    PreviewComponent,
    uploadFile,
    previewImageClassName,
    removeRequested,
    fileName,
    loading,
    fileThumbnailUrl,
    className,
    uploadButtonText,
    removeImageText,
    inputAccept,
    persistFileSelectInput = false,
    maxFileSize,
    onFileUploadTooLarge,
    disabled,
    dataTestIdInput,
    dataTestIdButton,
    showDeleteButtonOnFileUploaded = false,
    showText = false,
    topText = undefined,
    fileExtensions,
    externalErrorMessage = undefined,
}) => {
    const fileExists = fileName != null;
    const translate = useIventisTranslate();
    const [errorMessage, setErrorMessage] = useState<errorTypes>();
    const selectFileForUpload = () => {
        const input: HTMLInputElement = document.createElement("input");
        input.type = "file";
        input.hidden = true;
        input.accept = inputAccept ?? "*";
        input.setAttribute("data-testid", dataTestIdInput ?? "select-file-input");
        document.body.appendChild(input);
        input.click();
        input.onchange = (event) => {
            const file = (event.target as HTMLInputElement).files[0];
            const fileSize = file.size / 1000000; // file.size is in bytes
            if (maxFileSize && fileSize > maxFileSize) {
                if (onFileUploadTooLarge) {
                    onFileUploadTooLarge();
                    return null;
                }
                return setErrorMessage("file_size_exceeded");
            }
            setErrorMessage(null);
            return uploadFile(file);
        };
    };

    const removeOrUpload = () => {
        if (showDeleteButtonOnFileUploaded && fileName) {
            return removeRequested();
        }
        return selectFileForUpload();
    };

    return (
        <>
            <>
                {(!fileExists || persistFileSelectInput) && (
                    <>
                        {showText && topText && <Body2>{topText}</Body2>}
                        <UploadFileInput
                            classNames={{ button: "file-upload-button", input: "file-upload-input", container: className }}
                            loading={loading}
                            disabled={loading || disabled}
                            fileName={fileName}
                            onInputClick={selectFileForUpload}
                            onButtonClick={removeOrUpload}
                            buttonText={uploadButtonText}
                            dataTestId={dataTestIdButton ?? "select-file-button"}
                            fileAdded={!(fileName === undefined || fileName === null || fileName === "")}
                            showDeleteButtonOnFileUploaded={showDeleteButtonOnFileUploaded}
                        />
                        {(errorMessage || externalErrorMessage) && (
                            <>
                                <ErrorText className="file-size-exceeded" data-testId="file-size-exceeded">
                                    {errorMessage === "file_size_exceeded" ? <span>{translate(Content.map6.fileUpload.max_file_size, { maxFileSize })}</span> : ""}
                                    {externalErrorMessage && !errorMessage ? <span>{externalErrorMessage}</span> : ""}
                                </ErrorText>
                            </>
                        )}
                        {maxFileSize != null && fileExtensions != null && !errorMessage && !externalErrorMessage && showText && (
                            <BottomText className="supported-files">
                                {translate(Content.map7.importGeoJson.reuploadGeoJson, { fileExtensions: fileExtensions.join(", "), size: `${maxFileSize} MB` })}
                            </BottomText>
                        )}
                    </>
                )}
                {fileExists && PreviewComponent != null && (
                    <PreviewComponent
                        className={previewImageClassName}
                        removeRequested={removeRequested}
                        loading={loading}
                        fileName={fileName}
                        fileThumbnailUrl={fileThumbnailUrl}
                        removeImageText={removeImageText}
                        disabled={disabled}
                    />
                )}
            </>
        </>
    );
};

export interface FileUploadPreviewComponentProps {
    removeRequested: () => void;
    loading: boolean;
    fileName?: string;
    fileThumbnailUrl?: string;
    removeImageText: string;
    className?: string;
    disabled?: boolean;
}

export const FileUploadImagePreviewComponent: React.FC<FileUploadPreviewComponentProps> = ({
    removeRequested,
    loading,
    fileName,
    fileThumbnailUrl,
    removeImageText,
    className,
    disabled,
}) => (
    <ImagePreviewContainer className={className}>
        <div className="preview-container">
            <ImagePreview className="image-preview" src={fileThumbnailUrl} />
        </div>
        <div className="image-controls">
            <Body2 style={{ display: fileName && !loading ? "" : "none" }} className="file-name">
                {fileName}
            </Body2>
            <Button disabled={fileThumbnailUrl === undefined || loading || disabled} variant="outlined" color="error" onClick={() => removeRequested()}>
                {loading ? (
                    <FontAwesomeIcon icon={["fas", "circle-notch"]} spin style={{ marginRight: inlineTextIconMargin }} />
                ) : (
                    <FontAwesomeIcon icon={["fal", "trash-undo"]} style={{ marginRight: inlineTextIconMargin }} />
                )}
                {removeImageText}
            </Button>
        </div>
    </ImagePreviewContainer>
);

const ImagePreview = styled.img`
    min-width: 0;
    width: 100%;
`;

const ImagePreviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${modalPadding};
    .image-controls {
        .file-name {
            margin-bottom: ${formGap};
        }
    }
`;

const ErrorText = styled(Body2)`
    color: ${({ theme }) => theme.secondaryColors.error};
    margin: -${formGap}px 0 ${formGap}px 0;
`;

const BottomText = styled(Body3)`
    color: ${({ theme }: { theme: Theme }) => theme.shades.two};
`;
