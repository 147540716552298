/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-body-style */
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useEffect, useState, DetailedHTMLProps, ElementType, HTMLAttributes } from "react";
import { assign, DoneInvokeEvent } from "xstate";
import { v4 as uuid } from "uuid";
import { useMachine } from "@xstate/react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import {
    GridReadyEvent,
    SuppressKeyboardEventParams,
    CellClickedEvent,
    ColDef,
    PostSortRowsParams,
    IRowNode,
    RowPinnedType,
    RowDragEndEvent,
    CellClassParams,
    Column,
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { toastsDoNothing } from "@iventis/toasts";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { StyledComponent } from "@emotion/styled";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThemeProvider } from "@mui/material";
import { DataTableTheme } from "@iventis/styles/src/data-table-theme";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { sanitiseForDOMToken, useConstant, useRenderJsxToString } from "@iventis/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultTheme, StyledTable as DefaultStyledTable, StyledTextCell } from "../styles/data-table.styles";
import { GroupHeaderCellComponent, HeaderCellComponent } from "./header-cell";
import {
    ColumnProps,
    DataTableCallbackRefs,
    DataTableGridOptions,
    HasDataTableValueChanged,
    IventisRowNode,
    RowModel,
    RowNetworkStatus,
    IventisTableData,
    IventisCellRendererParams,
} from "../types/data-table.types";
import { autoSizeColumnsOnFirstDataRendered, clearAllSelection, createBasicTemplateRow, iventisComparator, selectSingleCell } from "../lib/grid.helpers";
import {
    dataTableMachine,
    GridMachineContext,
    GridMachineEvents,
    machineEvents as MachineEvents,
    QueueContext,
    DataTableOperations,
    DeleteConfirmedEvent,
} from "../lib/data-table-machine";
import { isValueValid, setRowNetworkStatus } from "../lib/internal-columns";
import { IndexCell } from "./index-cell";
import { COUNT_COL_ID, INDEX_COL_ID } from "../lib/constants";
import { InsertColumnComponent } from "./insert-column-header-component";
import { InsertRowAboveComponent } from "./insert-row-above";
import { GroupCellComponent } from "./group-cell";

LicenseManager.setLicenseKey(
    "CompanyName=Iventis ltd,LicensedApplication=Iventis,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-040182,SupportServicesEnd=27_March_2024_[v2]_MTcxMTQ5NzYwMDAwMA==1c63ecefbb52e7a1d7f3d230a3d17589"
);

export const TextCell = (props: IventisCellRendererParams) => (
    <StyledTextCell data-testid={sanitiseForDOMToken(`data-table-cell-row-${props.node.data?.name}-column-${props.column.getColDef().headerName}`)}>
        {props.valueFormatted ?? props.value}
    </StyledTextCell>
);

export const defaultContext = { translate: (word) => word, timezone: "Europe/London", toast: toastsDoNothing };

const defaultGridOptionsStatic: DataTableGridOptions<IventisTableData> = {
    headerHeight: 44,
    rowHeight: 42,
    enableFillHandle: false,
    stopEditingWhenCellsLoseFocus: true,
    onFirstDataRendered: autoSizeColumnsOnFirstDataRendered,
    suppressMultiRangeSelection: false,
    suppressContextMenu: true,
    undoRedoCellEditing: true,
    rowSelection: "multiple",
    undoRedoCellEditingLimit: 20,
    enableCellChangeFlash: true,
    context: defaultContext,
    animateRows: true,
    domLayout: "autoHeight",
};

const getCellClass = <TData extends IventisTableData>(params: CellClassParams<TData>) =>
    sanitiseForDOMToken(
        `${params.colDef.field} data-table-cell-row-${params.node.data?.name ?? params.node.data?.groupDisplayValue ?? ""}-column-${params.colDef.headerName}`,
        "-"
    ).replaceAll(" ", "-");

const defaultServerSideGroupingGridOptions = <TData extends IventisTableData>(): Partial<DataTableGridOptions<TData>> => ({
    rowGroupPanelShow: "always",
    groupDisplayType: "singleColumn",
    groupAllowUnbalanced: true,
    isRowSelectable: (row) => !row.group,
    autoGroupColumnDef: {
        headerComponent: GroupHeaderCellComponent,
        cellRenderer: GroupCellComponent,
        cellClass: getCellClass,
    },
    onCellFocused: (e) => {
        if (typeof e.column === "string" ? e.column === "ag-Grid-AutoColumn" : e.column.getColId() === "ag-Grid-AutoColumn") {
            e.api.clearFocusedCell();
        }
    },
});

const groupingGridOptions = <TData extends IventisTableData>(): Record<DataTableProps<IventisTableData>["grouping"], Partial<DataTableGridOptions<TData>>> => ({
    none: {},
    serverSideGrouping: defaultServerSideGroupingGridOptions<TData>(),
});

const components = {
    textCell: TextCell,
    indexCell: IndexCell,
};

type DataTableProps<TData extends IventisTableData> = {
    StyledTable?: StyledComponent<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { theme?: DataTableTheme; as?: ElementType<any> } & { $pinnedRowHeight?: number; $columnWidths?: number; $rowGroupingEnabled?: boolean },
        DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        {}
    >;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowData?: TData[];
    gridOptions?: DataTableGridOptions<TData>;
    columns: ColumnProps[];
    theme?: DataTableTheme;
    editRowAsync?(context: GridMachineContext<TData>): Promise<unknown>;
    deleteRowAsync?(context: GridMachineContext<TData>): Promise<TData>;
    /**
     * Callback called when a row needs to be inserted, usually used for network requests.
     * The row will already exist in ag-grid's data model by this point.
     * If an ID is changed, you will need to manually set the row data using the ref's getApi()
     * @param context
     */
    insertRowAsync?(context: GridMachineContext<TData>): Promise<unknown>;
    insertColumnAsync?(context: GridMachineContext<TData>): Promise<unknown>;
    onInsertColumn?(): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onNetworkRequestSuccess?(queueContext: QueueContext<TData>, event: DoneInvokeEvent<any>): void;
    onNetworkRequestError?(queueContext: QueueContext<TData>): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shouldRowBeIndexed?(row: IventisRowNode<TData>): boolean;
    dataTableId?: string;
    onRowSelected?(e: IRowNode<TData>[]): void;
    onInvalidRowCountChanged?(count: number): void;
    onDeleteRequested?(rows: TData[]): void;
    onDeleteHandled?(): void;
    defaultSort?: { colId: string; sort: "desc" | "asc"; sortIndex: number }[];
    hasValueChanged?: HasDataTableValueChanged;
    requiredFields?: string[];
    validateRow?: (row: IRowNode<TData>) => boolean;
    sideEffectsOnCellValueChanged?: ColDef<TData>["onCellValueChanged"];
    canAddColumn?: boolean;
    setShowObjectDeletionDialog?: (showObjectDeletionDialog: boolean) => void;
    draggableRows?: boolean;
    enableEmbeddedAddRow?: boolean;
    createTemplateRow?: () => TData;
    grouping?: "none" | "serverSideGrouping";
    handleRowOrderUpdate?: (row: TData, idBefore: string, idAfter: string) => void;
    onRowGroupChanged?: (columns: Pick<Column, "getColId" | "isRowGroupActive">[]) => void;
    onColumnVisibilityChanged?: (columnId: string, visible: boolean) => void;
};

// eslint-disable-next-line no-underscore-dangle
function _DataTable<TData extends IventisTableData>(
    {
        StyledTable = DefaultStyledTable,
        rowData,
        gridOptions,
        columns,
        theme = defaultTheme,
        editRowAsync,
        deleteRowAsync,
        insertRowAsync,
        insertColumnAsync,
        onInsertColumn,
        onNetworkRequestSuccess,
        onNetworkRequestError,
        shouldRowBeIndexed,
        dataTableId,
        onRowSelected,
        onInvalidRowCountChanged,
        onDeleteRequested,
        onDeleteHandled,
        defaultSort,
        hasValueChanged = () => true,
        requiredFields = [],
        validateRow = () => true,
        sideEffectsOnCellValueChanged,
        canAddColumn = false,
        setShowObjectDeletionDialog,
        enableEmbeddedAddRow,
        createTemplateRow,
        grouping = "none",
        draggableRows,
        handleRowOrderUpdate,
        onRowGroupChanged,
        onColumnVisibilityChanged,
    }: DataTableProps<TData>,
    ref: React.ForwardedRef<DataTableCallbackRefs<TData>>
) {
    const [columnWidths, setColumnWidths] = useState(0);
    const gridRef = useRef<AgGridReact<TData>>();
    const [api, columnApi] = [gridRef.current?.api, gridRef.current?.columnApi] as const;
    const isRowModelOnServer = () => [RowModel.Infinite, RowModel.ServerSide].includes(api.getModel().getType() as RowModel);
    /** Applies the current sort method. An example use case is when we edit a cell value for a column that has sort applied to it, without calling this it may be out of place   */
    const restoreSort = () => {
        if (isRowModelOnServer()) {
            // For infinite rows we must just clear all selection as we don't know where it may end up
            clearAllSelection(api);
            return;
        }

        // Gather the selection/focus/editing state
        const selectedColumn = api.getFocusedCell()?.column;
        let selectedRow: IRowNode<TData>;
        api.forEachNode((row) => {
            if (row.isSelected()) {
                selectedRow = row;
            }
        });
        const editingCells = api.getEditingCells();

        if (selectedRow == null || selectedColumn == null) {
            api.refreshClientSideRowModel("sort");
            return;
        }

        // Clear the selection because it will be out of date after sorting
        api.clearFocusedCell();

        // Apply the sort
        api.refreshClientSideRowModel("sort");

        // Get the row that was selected before and select it
        const newRow = api.getRowNode(selectedRow.id);
        selectSingleCell(selectedColumn, newRow, api);

        // If editing, begin editing again
        if (editingCells?.length > 0) {
            api.startEditingCell({ rowIndex: newRow.rowIndex, colKey: editingCells?.[0].column });
        }
    };
    /** Similar to restoreSort, we want to refresh the index column values when rows move around */
    const refreshIndex = (args = { api } as PostSortRowsParams<TData>) => {
        // refresh index based on sort
        let indexTotal = 0;

        const rowModelType = args.api.getModel().getType() as RowModel;

        if (rowModelType === RowModel.Infinite) {
            args.api.refreshInfiniteCache();
            return;
        }

        if (rowModelType === RowModel.ServerSide) {
            const state = api.getServerSideGroupLevelState();
            state.forEach(({ route }) => {
                args.api.refreshServerSide({ route });
            });
            return;
        }

        args.api.forEachNodeAfterFilterAndSort((node) => {
            if (shouldRowBeIndexed == null) {
                node.setDataValue(INDEX_COL_ID, node.rowIndex + 1);
            } else if (shouldRowBeIndexed(node)) {
                indexTotal += 1;
                const actualRowIndex = node.rowIndex + 1;
                node.setDataValue(INDEX_COL_ID, actualRowIndex - (actualRowIndex - indexTotal));
            } else {
                node.setDataValue(INDEX_COL_ID, null);
            }
        });
    };

    const machine = useConstant(() => dataTableMachine<TData>());

    const [, send] = useMachine<typeof machine>(machine, {
        context: { hasValueChanged },
        services: {
            editRowAsync,
            deleteRowAsync,
            insertRowAsync,
            insertColumnAsync,
        },
        guards: {
            isSortAppliedToColumn: (_, event) => {
                const sorts = columnApi.getColumnState().filter((c) => c.sort != null);
                return sorts.some((s) => s.colId === event.payload.update[0]);
            },
        },
        actions: {
            refreshIndex: () => refreshIndex(),
            restoreSort,
            refreshGridData: () => {
                if (isRowModelOnServer()) api.refreshInfiniteCache();
            },
            deleteRow: (_, event) => {
                // If the event comes directly from delete confirmed, it has a payload.
                // If it comes from the result of an delete row async, it has a .data.
                const row = (event as DeleteConfirmedEvent<TData>).payload || (event as { data: TData }).data;
                if (isRowModelOnServer()) {
                    api.getRowNode(row.id)?.setSelected(false);
                    // Infinite row models do not allow local row removal.
                    // Instead, the deletion would fall to refreshGridData which would refresh the infinite cache.
                    return;
                }

                if (row.networkStatus === RowNetworkStatus.PendingCreation) {
                    api.stopEditing(true);
                    api.applyTransaction({ remove: [row] });
                    onInvalidRowCountChanged?.(countInvalidRows());
                    return;
                }

                const rowsToDelete = [];
                api.forEachNode((node: IventisRowNode) => {
                    if (node.data.id === row.id) {
                        rowsToDelete.push(node.data);
                    }
                });
                api.applyTransaction({ remove: rowsToDelete });
            },
            copy: () => {
                api.copySelectedRangeToClipboard({ includeGroupHeaders: false, includeHeaders: false });
            },
            onNetworkRequestSuccess: assign((context, event) => {
                if (onNetworkRequestSuccess != null) {
                    onNetworkRequestSuccess(context.queue[0], event);
                }
                const firstInQueue = context.queue[0];

                if (firstInQueue.operation === DataTableOperations.Edit) {
                    // If the request was an edit, we need to remove the loading spinner by setting the network status back
                    // Need to find all the nodes with the same Id and set their network status as well
                    api.forEachNode((node) => {
                        if (node.data?.id === firstInQueue.node.data.id) {
                            setRowNetworkStatus(node, RowNetworkStatus.ExistsOnServer);
                        }
                    });
                }
                onInvalidRowCountChanged?.(countInvalidRows());
                return context;
            }),
            onNetworkRequestError: assign((context) => {
                if (onNetworkRequestError != null) onNetworkRequestError(context.queue[0]);
                return context;
            }),
            onDeleteRequested: assign((context) => {
                const rows = api.getSelectedRows();
                if (onDeleteRequested != null) onDeleteRequested(rows);
                return context;
            }),
            onDeleteHandled: assign((context) => {
                if (onDeleteHandled != null) onDeleteHandled();
                return context;
            }),
        },
    });

    function updateTotalColumnsWidth(params) {
        // rerun our autosize so columns are correct size
        autoSizeColumnsOnFirstDataRendered(params);
        // then work out total column widths
        const totalColumnWidths = params.columnApi.columnModel.displayedColumns.reduce((acc, column) => {
            const updatedAcc = column.actualWidth + acc;
            return updatedAcc;
        }, 0);
        // and set it so we can use it to set grid width
        setColumnWidths(totalColumnWidths);
    }

    function onGridSizeChanged(params) {
        updateTotalColumnsWidth(params);
    }

    const defaultGridOptionsDynamic: Partial<DataTableGridOptions<TData>> = {
        onSortChanged: (event) => {
            // This callback is triggered when a user sorts using the column header. If this is the case, we clear cell and row selection
            clearAllSelection(event.api);
        },
        onSelectionChanged: (e) => {
            const rows = e.api.getSelectedNodes();
            // Ensure all nodes that have the same data id are selected.
            const selectedRowIds = rows?.map((row) => row.data.id);
            if (selectedRowIds?.length > 0) {
                e.api.forEachNode((node) => {
                    if (selectedRowIds.includes(node?.data?.id) && !node.isSelected()) {
                        node.setSelected(true, false);
                    }
                });
            }
            // If the focused cell is not in the selection, make sure we clear it
            const focusedCell = e.api.getFocusedCell();
            // eslint-disable-next-line eqeqeq
            if (focusedCell && !rows.some((row) => row.rowIndex === focusedCell.rowIndex && row.rowPinned == focusedCell.rowPinned)) {
                e.api.clearFocusedCell();
            }
            if (e.source === "rowClicked") onRowSelected?.(rows);
        },
        onColumnRowGroupChanged: (e) => {
            onRowGroupChanged?.(e.columns);
            // If we are grouping rows, make sure the count column is visible
            const someColumnsAreGrouped = e.columnApi.getRowGroupColumns()?.length > 0;
            e.columnApi.setColumnVisible(COUNT_COL_ID, someColumnsAreGrouped);
        },
        onFirstDataRendered(params) {
            updateTotalColumnsWidth(params);
            gridOptions?.onFirstDataRendered?.(params);
        },
        onColumnVisible: (event) => {
            // If the column has been hidden internally, then we must inform the parent component
            if (event.source !== "gridOptionsChanged") {
                onColumnVisibilityChanged?.(event.column.getColId(), event.visible);
            }
        },
        ...groupingGridOptions<TData>()[grouping],
    };

    const sortGrid = (event) => {
        const columnState = {
            state: defaultSort,
        };
        event.columnApi.applyColumnState(columnState);
    };

    const onGridReady = (params: GridReadyEvent<TData>) => {
        if (defaultSort) {
            sortGrid(params);
        }
    };

    const suppressColumnHeaderKeyboardEvent = useCallback((params) => {
        const e = params.event;

        // Allow any 'user-tabbing' users to access the interactive elements within the header cell component
        if (e.key === "Tab" || ((e.key === "Space" || e.key === "Enter") && !document.activeElement.classList.contains("ag-header-cell"))) {
            return true;
        }
        return false;
    }, []);

    /**
     * Handle's deletion via machine instead of internally with ag grid.
     *
     * @param {SuppressKeyboardEventParams} params - ag grid params for suppressing keyboard events
     * @returns {boolean} - if true, we suppress the keyboard event
     */
    const suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        if (params.event.key === "Delete" && !params.editing) {
            const selectedRow = api?.getSelectedRows()[0];
            if (selectedRow == null) {
                return false;
            }
            send({ type: MachineEvents.DELETE_REQUEST, payload: selectedRow });
            return true;
        }
        return false;
    };

    const rowIsValid = (node: IRowNode<TData>) =>
        requiredFields.every((field) => {
            const value = node.data[field];
            const valueValid = isValueValid(node, value, true);
            return valueValid;
        }) && validateRow(node);

    const countInvalidRows = () => {
        let invalidRows = 0;
        api.forEachNode((node) => {
            if (!rowIsValid(node)) {
                invalidRows += 1;
            }
        });
        return invalidRows;
    };

    const lastClickedCell = useRef<{ rowIndex: number; columnId: string; rowPinned: RowPinnedType }>();

    const lastClickTimeout = useRef<NodeJS.Timeout>();

    const onCellClicked = (event: CellClickedEvent<IventisTableData>) => {
        const focusCell = event.api.getFocusedCell();

        const selectedRows = event.api.getSelectedNodes();

        if (event.rowPinned) {
            selectedRows.forEach((row) => row.setSelected(false));
            setTimeout(() => event.api.startEditingCell({ rowIndex: event.rowIndex, colKey: event.column.getColId(), rowPinned: event.rowPinned }), 0);
            return;
        }

        // eslint-disable-next-line eqeqeq
        const cellSelectionHasCausedRowSelection = selectedRows.some((row) => row.rowIndex === event.rowIndex && row.rowPinned == event.rowPinned);

        if (focusCell == null && !cellSelectionHasCausedRowSelection) {
            // In some case where selection of cells has been suppressed, the cell may not be focused
            return;
        }

        const newCellClick = { rowIndex: event.rowIndex, columnId: event.column.getColId(), rowPinned: event.rowPinned };
        const previousCellClick = lastClickedCell.current;

        lastClickedCell.current = newCellClick;

        if (
            previousCellClick &&
            previousCellClick.columnId === newCellClick.columnId &&
            previousCellClick.rowIndex === newCellClick.rowIndex &&
            // eslint-disable-next-line eqeqeq
            previousCellClick.rowPinned == newCellClick.rowPinned
        ) {
            const editingCell = api.getEditingCells()[0];
            if (editingCell) {
                return;
            }

            const startEditing = () => api.startEditingCell({ rowIndex: newCellClick.rowIndex, colKey: newCellClick.columnId, rowPinned: newCellClick.rowPinned });

            if (focusCell.column.getColDef().cellEditorPopup === false) {
                startEditing();
                return;
            }

            /*
                    If the cell editor is a popup cell editor, wait a timeout to allow for double clicking to occur.
                    This is because clicking once can bring up the poup, and then clicking again immediately closes the cell editor.
                    Instead have this delay, so they don't close the popover on double click.
                */
            clearTimeout(lastClickTimeout.current);
            lastClickTimeout.current = setTimeout(() => {
                startEditing();
            }, 150);
        }
    };

    useEffect(() => () => clearTimeout(lastClickTimeout.current), []);

    useImperativeHandle(ref, () => {
        return {
            getApi() {
                return api;
            },
            getColumnApi() {
                return columnApi;
            },
            deleteRequest(skipConfirmation = true) {
                if (!skipConfirmation) {
                    return setShowObjectDeletionDialog(true);
                }
                const rowToDelete = api.getSelectedRows()[0];
                const events: GridMachineEvents<TData>[] = [{ type: MachineEvents.DELETE_REQUEST, payload: rowToDelete }];

                events.push({ type: MachineEvents.DELETE_CONFIRMED, payload: rowToDelete });

                return send(events);
            },
            deleteConfirmed() {
                let selectedRow: TData;
                api.getSelectedNodes().forEach((selectedNode) => {
                    if (selectedNode.data.networkStatus !== RowNetworkStatus.PendingCreation) {
                        setRowNetworkStatus(selectedNode, RowNetworkStatus.Saving);
                    }
                    selectedRow = selectedNode.data;
                });
                send({ type: MachineEvents.DELETE_CONFIRMED, payload: selectedRow });
            },
            deleteCancelled() {
                send(MachineEvents.DELETE_CANCELLED);
            },
            undo() {
                api.undoCellEditing();
            },
            redo() {
                api.redoCellEditing();
            },
            copy() {
                send(MachineEvents.COPY);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            insertRow(data: any, index: number) {
                // Prevent row insertion if one row is already invalid
                const {
                    add: [newRow],
                } = api.applyTransaction({ add: data, addIndex: index });

                api.deselectAll();
                api.clearFocusedCell();
                newRow.setSelected(true);

                const indexColumn = columnApi.getColumn(INDEX_COL_ID);
                // Select the index cell of the new row
                selectSingleCell(indexColumn, newRow, api);

                // This adds the exclamation mark to the index cell
                setRowNetworkStatus(newRow, RowNetworkStatus.PendingCreation);

                onInvalidRowCountChanged?.(countInvalidRows());

                // Only submit the insert if the row is immediately valid
                if (rowIsValid(newRow)) {
                    setRowNetworkStatus(newRow, RowNetworkStatus.Saving);
                    send({ type: MachineEvents.INSERT, payload: newRow });
                }
                // If the row is not valid, it will just sit in ag-grid's data model until it's valid.
            },
            insertColumn(data: ColDef<TData>) {
                const columnDefs: ColDef<TData>[] = api.getColumnDefs();
                const newColumns = columnDefs.slice(0, -1).concat(data).concat(columnDefs.slice(-1));
                api.setColumnDefs(newColumns);
            },
            refreshIndex,
            restoreSort,
            selectFromOutside(currentOutsideSelection) {
                const currentDataTableSelection = api.getSelectedNodes();

                // If the selection is the same, it's likely the selection originated from inside
                const selectionIsTheSame =
                    currentDataTableSelection.every((s) => currentOutsideSelection.includes(s.data.id)) &&
                    currentOutsideSelection.every((id) => currentDataTableSelection.some((s) => id === s.data.id));
                if (selectionIsTheSame) {
                    return;
                }

                // Clear away any cell selection
                api.clearFocusedCell();

                // Deselect any current selection that no longer exists outside
                currentDataTableSelection.forEach((row) => {
                    if (!currentOutsideSelection.some((id) => id === row.id)) {
                        row.setSelected(false);
                    }
                });

                // Ensure the outside selection is selected in the table
                currentOutsideSelection.forEach((id) => {
                    api.getRowNode(id)?.setSelected(true);
                });
            },
            startEditingNewRow: () => {
                const column = columns[0];
                // if we have a pinned row, select that to edit, otherwise search for the template cell row
                if (api.getPinnedBottomRow(0) != null) {
                    api.startEditingCell({ colKey: column.agGridColumnProps.field, rowIndex: 0, rowPinned: "bottom" });
                } else {
                    api.forEachNode((node) => {
                        if (node.data?.networkStatus === RowNetworkStatus.Template) {
                            api.startEditingCell({ colKey: column.agGridColumnProps.field, rowIndex: node.rowIndex });
                        }
                    });
                }
            },
        };
    });

    const columnsWithMetadata: ColDef[] = [
        {
            width: 44,
            headerName: "",
            field: INDEX_COL_ID,
            pinned: "left" as const,
            editable: false,
            cellClass: "locked-col index-cell",
            cellRenderer: "indexCell",
            lockPosition: true,
            suppressMenu: true,
            resizable: false,
            suppressKeyboardEvent,
            valueGetter: (params) => (shouldRowBeIndexed != null ? params.data.index : params.node.rowIndex + 1), // If shouldRowBeindexed is provided, we must use the given index
            enableValue: false,
            rowDrag: (params) => {
                if (params.data.networkStatus === RowNetworkStatus.Template) {
                    return false;
                }
                if (draggableRows) {
                    return true;
                }
                return false;
            },
        } as ColDef,
        ...(grouping === "none"
            ? []
            : [
                  {
                      width: 100,
                      headerName: gridOptions.context.translate("(Count)"),
                      field: COUNT_COL_ID,
                      editable: false,
                      cellClass: getCellClass,
                      suppressMenu: true,
                      cellRenderer: "textCell",
                      valueGetter: (params) => params.node.data.count,
                      headerComponent: HeaderCellComponent,
                      initialHide: true,
                  },
              ]),
    ]
        .concat(
            columns.map((column) => ({
                suppressHeaderKeyboardEvent: suppressColumnHeaderKeyboardEvent,
                suppressKeyboardEvent,
                headerComponent: column.agGridColumnProps.headerComponent || HeaderCellComponent,
                onCellValueChanged: (args) => {
                    if (sideEffectsOnCellValueChanged) {
                        sideEffectsOnCellValueChanged(args);
                    }

                    const { node, newValue, oldValue, colDef } = args;
                    // Update parent if necessary
                    if (node.parent?.level >= 0 && colDef.aggFunc === "sum") {
                        const diff = newValue - oldValue;
                        const parentValue = typeof colDef.valueGetter === "string" ? colDef.valueGetter : colDef.valueGetter?.({ ...args, node: node.parent, getValue: undefined });
                        if (typeof parentValue === "number" && diff !== 0) {
                            node.parent.setDataValue(colDef.field, parentValue + diff);
                        }
                    }
                    // Groups do not save changes to server and non editable columns shouldn't either
                    if (node.group || !(typeof colDef.editable === "boolean" ? colDef.editable : colDef.editable?.({ ...args }))) {
                        return;
                    }
                    const { field } = column.agGridColumnProps;
                    switch (node.data.networkStatus as RowNetworkStatus) {
                        case RowNetworkStatus.Template: {
                            const {
                                add: [newRow],
                            } = args.api.applyTransaction({ add: [{ ...node.data, id: uuid(), networkStatus: RowNetworkStatus.Saving }] });
                            if (requiredFields.includes(field) && rowIsValid(node)) {
                                send({ type: MachineEvents.INSERT, payload: newRow });

                                node.setData(createTemplateRow?.() ?? createBasicTemplateRow());

                                clearAllSelection(args.api);
                                node.setSelected(true);

                                // Unfortunately, blindly pushing this to the back of the call stack is the only way I could get ag grid to start editing the new cell internally.
                                // So a setTimeout is used here
                                setTimeout(() => args.api.startEditingCell({ rowIndex: node.rowIndex, colKey: args.column, rowPinned: "bottom" }), 0);
                            } else {
                                // This adds the exclamation mark to the index cell
                                // We dont want to send the row off if it is not valid as this would result in error so put it in pending
                                setRowNetworkStatus(newRow, RowNetworkStatus.PendingCreation);
                            }
                            break;
                        }
                        case RowNetworkStatus.PendingCreation:
                            if (requiredFields.includes(field) && rowIsValid(node)) {
                                setRowNetworkStatus(node, RowNetworkStatus.Saving);
                                send({ type: MachineEvents.INSERT, payload: node });
                            }
                            break;
                        case RowNetworkStatus.Saving:
                        case RowNetworkStatus.ExistsOnServer:
                        case undefined:
                            // Undefined row network statuses are assumed to exist on the server
                            setRowNetworkStatus(node, RowNetworkStatus.Saving);
                            send(MachineEvents.EDIT, {
                                payload: { node, update: [field, newValue], oldValue },
                            });
                            break;
                        default:
                            throw new Error("Network status not handled");
                    }
                },
                ...column.agGridColumnProps,
                cellRendererParams: {
                    // Add the required parameter to all cell renderers, so they know whether they need to affect their appearance depending on the required state
                    required: requiredFields.includes(column.agGridColumnProps.field),
                    ...column.agGridColumnProps.cellRendererParams,
                },
                cellEditorParams: {
                    // Add the required parameter to all cell editors, so they know whether to block edits on non-required fields
                    required: requiredFields.includes(column.agGridColumnProps.field),
                    ...column.agGridColumnProps.cellEditorParams,
                },
                comparator: iventisComparator(column.agGridColumnProps.comparator),
                cellClass: (params: CellClassParams<TData>) => getCellClass(params),
            }))
        )
        // Append some internal data-table columns to the inputted columns
        .concat({
            field: "networkStatus",
            hide: true,
        });

    const rows: TData[] = useMemo(() => [...(rowData ?? gridOptions.rowData ?? [])], [rowData]);

    const handleDragEnd = (e: RowDragEndEvent) => {
        // e.api doesnt have refresh cells so we use set selected here to ensure the index is correctly updated for each row item
        e.node.setSelected(true);

        const nodeIndex = e.node.rowIndex;

        // use row index to update
        const nextRow = e.node.parent.allLeafChildren.filter((row) => row.rowIndex > nodeIndex);
        const prevRow = e.node.parent.allLeafChildren.filter((row) => row.rowIndex === nodeIndex - 1);

        // add id of node liste item before

        handleRowOrderUpdate(e.node.data, prevRow.length ? prevRow[0].data.id : null, nextRow.length ? nextRow[0].data.id : null);
    };

    const handleRowDragStart = (e) => clearAllSelection(e.api);

    const { loadingIcons, icons: renderedIcons } = useRenderJsxToString(
        {
            rowDrag: <FontAwesomeIcon icon={["fas", "bars"]} />,
            columnDrag: <FontAwesomeIcon icon={["fas", "grip-dots"]} />,
            cancel: <FontAwesomeIcon icon={["far", "xmark"]} />,
            groupExpanded: <FontAwesomeIcon fontSize="16px" color={theme.dataTableTheme.typography.lessSubdued} icon={["far", "chevron-down"]} />,
            groupContracted: <FontAwesomeIcon fontSize="16px" color={theme.dataTableTheme.typography.lessSubdued} icon={["far", "chevron-right"]} />,
            columnMoveMove: <FontAwesomeIcon icon={["far", "arrows-up-down-left-right"]} />,
            dropNotAllowed: <FontAwesomeIcon icon={["far", "ban"]} />,
            rowGroupPanel: <InsertRowAboveComponent fill={theme.dataTableTheme.typography.moreSubdued} />,
            columnMoveGroup: <InsertRowAboveComponent fill={theme.dataTableTheme.typography.moreSubdued} height="10.5" width="12" />,
            sortAscending: <FontAwesomeIcon icon="caret-down" />,
            sortDescending: <FontAwesomeIcon icon="caret-up" />,
            groupLoading: <FontAwesomeIcon icon="spinner" />,
            columnMoveHide: <FontAwesomeIcon icon="eye-slash" />,
            smallRight: <FontAwesomeIcon fontSize="14px" icon={["far", "chevron-right"]} />,
        },
        "svg"
    );

    const combinedGridOptions: DataTableGridOptions<IventisTableData> = useMemo(
        () => ({
            ...defaultGridOptionsStatic,
            ...defaultGridOptionsDynamic,
            ...gridOptions,
            pinnedBottomRowData: enableEmbeddedAddRow ? [createTemplateRow?.() ?? createBasicTemplateRow()] : [],
            // here is where we use our font awesome icons to override the ag-grid ones
            // icon still needs to be imported in font-awesome.ts
            icons: renderedIcons,
            rowData: rows,
            onRowDragEnd: (e) => handleDragEnd(e),
            onRowDragEnter: (e) => handleRowDragStart(e),
        }),
        [renderedIcons]
    );
    if (loadingIcons) {
        // No need for a loading spinner here as it is literally milliseconds to render
        return null;
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StyledTable
                        $columnWidths={columnWidths}
                        $rowGroupingEnabled={grouping !== "none"}
                        data-cy="data-table"
                        data-testid={dataTableId}
                        id={dataTableId}
                        className="ag-grid-iventis"
                        $pinnedRowHeight={enableEmbeddedAddRow ? combinedGridOptions.rowHeight : 0}
                    >
                        <AgGridReact
                            onGridSizeChanged={onGridSizeChanged}
                            ref={gridRef}
                            onGridReady={onGridReady}
                            onCellClicked={onCellClicked}
                            defaultColDef={{ resizable: true }}
                            gridOptions={combinedGridOptions}
                            components={components}
                            modules={[RowGroupingModule, ServerSideRowModelModule, ClientSideRowModelModule]}
                            postSortRows={refreshIndex}
                            columnDefs={columnsWithMetadata}
                        />
                    </StyledTable>
                    {canAddColumn && <InsertColumnComponent insertColumn={() => onInsertColumn()} />}
                </LocalizationProvider>
            </ThemeProvider>
        </>
    );
}

export const DataTable = forwardRef(
    _DataTable as <TData extends IventisTableData>(props: DataTableProps<TData> & { ref?: React.ForwardedRef<DataTableCallbackRefs<TData>> }) => ReturnType<typeof _DataTable>
);
