/* eslint-disable no-console */
import { CheckedCheckBox, Label1, UncheckedCheckBox, sectionalMargin, Body2, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import React, { FunctionComponent, useState } from "react";
import { TextField, Checkbox, LinearProgress, useTheme } from "@mui/material";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";

import { fileToBase64, getImageDimensions, resizeImageDimensionsGivenADesiredLength } from "@iventis/utilities";
import { checkboxWithLabelCss } from "@iventis/styles/src/atomic-rules";
import { useQuery } from "@tanstack/react-query";
import { Asset } from "@iventis/domain-model/model/asset";
import { FileUploadComponent, FormWizardTemplate, ColourPaletteIcon, DisabledOverlay } from "../index";
import { MultipleValueTextInput } from "./selectors/multi-value-text-input";
import { ImageUploadPreviewResizer } from "./image-preview-resizer";
import { FromGapComponent } from "./form-gap";

export type UploadIconData = {
    name: string;
    image: { fileName: string; imageUrl: string; height?: number; width?: number };
    categories: string[];
    metaData: { sdf: boolean };
};

export const UploadIconForm: FunctionComponent<{
    close: () => void;
    uploadRemotely: (data: UploadIconData) => Promise<Asset>;
    translate?: (text: string) => string;
    categories?: string[];
    getExistingData?: (id: string) => Promise<UploadIconData>;
    existingId?: string;
    setSelectedId?: (id: string) => void;
    // eslint-disable-next-line prettier/prettier
    acceptedFileInput?: `image/${string}`;
}> = ({ close, uploadRemotely, categories, translate = (text) => text, getExistingData, existingId, setSelectedId, acceptedFileInput = "image/*" }) => {
    const theme = useTheme<Theme>();

    const [state, setState] = useState<"idle" | "loading" | "error">("idle");

    const defaultData: UploadIconData = {
        name: null,
        image: { fileName: null, imageUrl: null },
        categories: [],
        metaData: { sdf: false },
    };

    // Form data
    const [data, _setData] = useState<UploadIconData>(defaultData);
    // Existing data stored here so it can be validated against.
    const [existingData, _setExistingData] = useState<UploadIconData>(undefined);

    const { isLoading, isFetching } = useQuery(["get-existing-asset", existingId], () => (existingId ? getExistingData(existingId) : Promise.resolve(defaultData)), {
        onSuccess(data) {
            _setData(data);
            if (existingId) {
                _setExistingData(data);
            }
        },
    });

    // Update form data
    const setData = <TField extends keyof UploadIconData, TValue extends UploadIconData[TField]>(field: TField, value: TValue) => _setData((d) => ({ ...d, [field]: value }));

    const defaultDimensionPixels = 30;

    // Upload image to local state
    const uploadIconLocally = async (file: File) => {
        const { withHeader } = await fileToBase64(file);
        const { height, width } = await getImageDimensions(file);
        const resizedDimensions = resizeImageDimensionsGivenADesiredLength(width, height, defaultDimensionPixels);
        setData("image", { fileName: file.name, imageUrl: withHeader, height: resizedDimensions.height, width: resizedDimensions.width });
        setState("idle");
    };

    const setImageDimensionsLocally = (value: { height: number; width: number }) => {
        setData("image", { ...data.image, height: value.height, width: value.width });
    };

    // Upload icon to remote storage
    const uploadIconRemotely = async () => {
        setState("loading");
        let err = false;
        try {
            const res = await uploadRemotely(data);
            setSelectedId(res?.id);
        } catch (error) {
            console.error(error);
            err = true;
            setState("error");
        }
        if (!err) {
            close();
        }
    };

    return (
        <FormWizardTemplate
            stages={[
                {
                    isValid: data?.name?.length > 0 && data?.image.fileName?.length > 0 && data?.image.imageUrl?.length > 0,
                    primaryButtonText: translate("Confirm"),
                    primaryButtonCallback: uploadIconRemotely,
                    secondaryButtons: [{ buttonText: translate("Cancel"), onButtonPressed: close }],
                    submitButtonDataCy: "confirm-icon-button",
                },
            ]}
            isSubmitting={state === "loading"}
            currentStage={0}
            title={existingId == null ? translate("Upload an icon") : translate("Edit icon")}
        >
            {(isLoading || isFetching) && <LinearProgress style={{ position: "absolute", width: "100%", top: "0px", left: "0px" }} />}
            {(isLoading || isFetching) && <DisabledOverlay />}
            <StyledFieldLabel id="name">{translate("Name of icon")}</StyledFieldLabel>
            <TextField
                type="text"
                data-testid="upload-asset-name"
                aria-labelledby="name"
                name="name"
                variant="outlined"
                value={data?.name || ""}
                onChange={(e) => setData("name", e.target.value)}
            />
            <FromGapComponent />
            {categories && (
                <MultipleValueTextInput
                    values={categories}
                    selectedValues={data?.categories}
                    onChange={(value) => setData("categories", value)}
                    title={translate("Categories")}
                    placeholder={translate("Search for categories")}
                    dataTestId="asset-categories-selector"
                />
            )}

            <StyledFieldLabel id="name">{translate("Image file")}</StyledFieldLabel>
            <StyledFileUploadComponent
                removeRequested={() => null}
                loading={false}
                fileName={data?.image.fileName}
                fileThumbnailUrl={data?.image.imageUrl}
                uploadFile={uploadIconLocally}
                uploadButtonText={translate("Select file")}
                removeImageText={null}
                inputAccept={acceptedFileInput}
                className="file-upload"
                persistFileSelectInput
                maxFileSize={1}
            />

            <StyledFieldLabel id="name">{translate("Image size")}</StyledFieldLabel>
            <StyledImageResizer>
                <ImageUploadPreviewResizer
                    fileThumbnailUrl={data?.image?.imageUrl}
                    height={data?.image?.height || 30}
                    width={data?.image?.width || 30}
                    maxHeight={105}
                    maxWidth={105}
                    disabled={data?.image.imageUrl == null || existingData?.image.imageUrl === data?.image.imageUrl}
                    updateDimensions={setImageDimensionsLocally}
                />
            </StyledImageResizer>
            <StyledFieldLabel id="name">{translate("Image style")}</StyledFieldLabel>
            <StyledCheckboxContainer>
                <Checkbox
                    checked={data?.metaData?.sdf ?? false}
                    icon={<UncheckedCheckBox size={15} borderStyle={`1px solid ${theme.typographyColors.subdued}`} backgroundColour={theme.secondaryColors.blank} />}
                    checkedIcon={
                        <CheckedCheckBox
                            size={15}
                            tickColour={theme.secondaryColors.blank}
                            borderStyle={`1px solid ${theme.primaryColors.focus}`}
                            backgroundColour={theme.primaryColors.focus}
                        />
                    }
                    color="primary"
                    onChange={(e) => setData("metaData", { ...data.metaData, sdf: e.target.checked })}
                    style={{ padding: "0px", alignSelf: "start" }}
                    aria-labelledby="enableColourCustomisation"
                />
                <Label1 id="enableColourCustomisation">
                    <span style={{ marginRight: "5px", fontSize: "0.938rem" }}>
                        <ColourPaletteIcon />
                    </span>
                    {translate("Enable colour customisation")}
                </Label1>
            </StyledCheckboxContainer>

            {state === "error" && (
                <>
                    <div role="presentation" style={{ height: sectionalMargin, margin: "auto" }} />
                    <Body2 style={{ color: "red" }}>{translate("Something went wrong")}</Body2>
                </>
            )}
        </FormWizardTemplate>
    );
};

export const StyledFileUploadComponent = styled(FileUploadComponent)`
    margin-bottom: ${sectionalMargin};
    width: 100%;
    .file-upload {
        width: 100%;
    }
    .file-upload-input {
        flex-grow: 1;
    }
`;

const StyledImageResizer = styled.div`
    margin-bottom: ${sectionalMargin};
`;

const StyledCheckboxContainer = styled.div`
    ${checkboxWithLabelCss}
    align-items: start;
    margin-bottom: ${sectionalMargin};
`;
