import React, { FunctionComponent } from "react";
import { Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, inlineTextIconMargin, inputHeight } from "@iventis/styles";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

interface UploadFileInputProps {
    onInputClick: () => void;
    onButtonClick: () => void;
    buttonText: string;
    fileName?: string;
    loading?: boolean;
    disabled?: boolean;
    dataTestId?: string;
    containerDataTestId?: string;
    classNames?: {
        input?: string;
        button?: string;
        container?: string;
    };
    fileAdded?: boolean;
    showDeleteButtonOnFileUploaded?: boolean;
}

export const UploadFileInput: FunctionComponent<UploadFileInputProps> = ({
    loading = false,
    disabled = false,
    classNames = {},
    fileName = "",
    buttonText,
    dataTestId = "select-file-button",
    containerDataTestId,
    onButtonClick,
    onInputClick,
    fileAdded = false,
    showDeleteButtonOnFileUploaded = false,
}) => {
    const icon: { prefix: IconPrefix; iconName: IconName } = {
        prefix: loading ? "fas" : "far",
        iconName: loading ? "circle-notch" : fileAdded && showDeleteButtonOnFileUploaded ? "trash" : "file-upload",
    };
    return (
        <StyledFileInput className={classNames.container} data-testid={containerDataTestId}>
            <TextField
                style={{ height: inputHeight }}
                variant="outlined"
                className={classNames.input}
                disabled={disabled}
                value={fileName}
                onClick={(e) => {
                    onInputClick();
                    (e.target as HTMLInputElement).blur();
                }}
                data-testId="select-file-display"
            />

            <Button className={classNames.button} disabled={loading || disabled} color="primary" variant="contained" onClick={() => onButtonClick()} data-testid={dataTestId}>
                <FontAwesomeIcon icon={[icon.prefix, icon.iconName]} spin={loading} style={{ marginRight: inlineTextIconMargin }} />
                {buttonText}
            </Button>
        </StyledFileInput>
    );
};

const StyledFileInput = styled.div`
    display: flex;

    input {
        color: ${(props) => props.theme.tertiaryColors.primary};
        text-decoration: underline;
        font-weight: 600;
    }

    .MuiInputBase-root {
        flex: 1 1;
        height: ${inputHeight};
        border-radius: 4px 0 0 4px;
    }

    button {
        border-radius: 0 4px 4px 0;
        box-shadow: none;
    }
`;
