import React, { useState } from "react";
import { Header3, sectionalMargin, styled } from "@iventis/styles";
import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AutocompleteWithLoading } from "@iventis/components";
import { toast, ToastType } from "@iventis/toasts";
import { getInstances, invalidateCache } from "./project-api-functions";

const DevCache = () => {
    const [instance, setInstance] = useState<string>(null);

    const { isLoading: isInstancesLoading, data: instances } = useQuery(["instances"], async () => {
        const instances = await getInstances();
        setInstance(instances[0].name);
        return instances;
    });

    const invalidateCacheWithToast = async (instance) => {
        const success = await invalidateCache(instance);
        if (success) {
            toast.success({ type: ToastType.BASIC, props: { message: "Cache invalidated successfully" } });
        }
    };

    return (
        <DevCacheContainer>
            <div className="header">
                <div className="title">
                    <Header3>Invalidate the cache</Header3>
                </div>
                <AutocompleteWithLoading
                    disableClearable
                    options={instances == null ? [] : instances.map((instance) => instance.name)}
                    value={instance}
                    onChange={(value) => {
                        setInstance(value);
                    }}
                    disabled={false}
                    loadingOptions={isInstancesLoading}
                    getOptionLabel={(option) => option}
                />
            </div>
            <Button variant="contained" disabled={instance == null} onClick={() => invalidateCacheWithToast(instance)}>
                Invalidate
            </Button>
        </DevCacheContainer>
    );
};

export default DevCache;

const DevCacheContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .title {
        display: flex;
        flex-direction: column;
    }
    .header {
        display: flex;
        align-items: center;
        padding: ${sectionalMargin};
        justify-content: space-between;
        box-sizing: border-box;
    }
`;
