/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyleType } from "@iventis/domain-model/model/styleType";
import React, { FunctionComponent } from "react";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { DataField } from "@iventis/domain-model/model/dataField";
import { editStyleFormGap, formGap } from "@iventis/styles/src/atomic-rules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "@emotion/react";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { Header4 } from "@iventis/styles/src/components/texts";
import { screenSizeBreakpoints, styled } from "@iventis/styles";
import { getLayerStyle } from "@iventis/layer-style-helpers/src/get-layer-style-helpers";
import { useWindowSize } from "@iventis/utilities";
import { PointStyles } from "@iventis/map-engine/src/utilities/style-helpers";
import { useDataFieldServices } from "@iventis/datafield-editor";
import { AreaEditStyleComponent } from "./area-edit-style";
import PreviewContainerComponent, { PreviewContainerCallbackRefs } from "./preview-container";
import { PointEditStyleDelegator } from "./point-edit-style-delegator";
import { ChangeStyleValue } from "./edit-style.helpers";
import { LineEditStyleDelegator } from "./line-edit-style-delegator";
import { IStyleService } from "./edit-layer-services";
import { tourLayerStylesClassName } from "./layer-tour";

export type EditStyleProps = {
    layer: MapLayer;
    onChange: (property: string, value: any) => void;
    onStyleTypeChanged: (styleType: StyleType) => void;
};

export type EditStyleHeaderProps = {
    style: LayerStyle;
    datafields: DataField[];
    name: string;
    children?: any;
    show: boolean;
    setShow: (set: boolean) => void;
    previewRef: React.MutableRefObject<PreviewContainerCallbackRefs>;
    editStyleService: IStyleService;
    imageCompressUrlGetter: (id: string, pixelRes: number) => Promise<string>;
};

export const EditStyleHeaderComponent: FunctionComponent<EditStyleHeaderProps> = ({
    previewRef,
    style,
    datafields,
    name,
    children = null,
    show,
    setShow,
    editStyleService,
    imageCompressUrlGetter,
}) => {
    const { dataFieldListItemsService } = useDataFieldServices();
    const handleChevronClick = () => {
        setShow(!show);
    };
    const { screenWidth } = useWindowSize();

    return (
        <>
            <StyledEditStyleHeaderContainer show={show}>
                <div className={`preview-header-chevron-container ${tourLayerStylesClassName}`}>
                    {screenWidth < screenSizeBreakpoints.extraExtraSmall && (
                        <PreviewContainerComponent
                            ref={previewRef}
                            layerStyle={style}
                            className="style-preview-small"
                            datafields={datafields}
                            assetGetter={editStyleService.assetGetter}
                            modelGetter={editStyleService.modelGetter}
                            imageCompressUrlGetter={imageCompressUrlGetter}
                            isAssetSdf={editStyleService.isAssetSdf}
                            getAttributeListItems={dataFieldListItemsService.getDataFieldListItems}
                            bustCacheIds={editStyleService.bustCacheIds}
                            assetRefreshObserver={editStyleService.refreshAssetEvent}
                            cypressWindowName="IVENTIS_LAYER_STYLE_PREVIEW_ENGINE_TESTING_FUNCTIONS"
                        />
                    )}
                    <Header4 className="title">{name}</Header4>
                    <FontAwesomeIcon id="chevron" size="xs" icon={show ? "chevron-up" : "chevron-down"} onClick={handleChevronClick} />
                </div>
                {show && <div className="style-properties">{children}</div>}
            </StyledEditStyleHeaderContainer>
        </>
    );
};

export const EditStyleComponent: FunctionComponent<EditStyleProps> = ({ layer, onChange, onStyleTypeChanged }) => {
    const style = getLayerStyle(layer);

    // units not implemented yet, however selection is
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const layerStyleChange: ChangeStyleValue = (styleProperty, value) => {
        if (!checkIfStylePropertyExists(styleProperty as string, style)) {
            throw new Error(`Style property ${String(styleProperty)} does not exist in style type ${style.styleType}`);
        }

        onChange(styleProperty as string, value);
    };

    const checkIfStylePropertyExists = (styleProperty: string, style: LayerStyle) => Object.keys(style).some((property) => styleProperty === property);

    let layerStyleOptions;

    switch (style.styleType) {
        case StyleType.Line:
        case StyleType.LineModel:
            layerStyleOptions = (
                <LineEditStyleDelegator layerStyle={style} changeStyleValue={layerStyleChange} onStyleTypeChanged={onStyleTypeChanged} dataFields={layer.dataFields} />
            );
            break;
        case StyleType.Area:
            layerStyleOptions = <AreaEditStyleComponent layerStyle={layer.areaStyle} changeStyleValue={layerStyleChange} dataFields={layer.dataFields} />;
            break;
        case StyleType.Point:
        case StyleType.Icon:
        case StyleType.Model:
            layerStyleOptions = (
                <PointEditStyleDelegator
                    layerStyle={style as PointStyles}
                    changeStyleValue={layerStyleChange}
                    onStyleTypeChanged={onStyleTypeChanged}
                    dataFields={layer.dataFields}
                />
            );
            break;
        default:
            throw new Error("Style type was not reconsigned");
    }
    return <StyledLayerStyleOptions>{style && layerStyleOptions}</StyledLayerStyleOptions>;
};

const StyledLayerStyleOptions = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    gap: ${editStyleFormGap};
    .style-container {
        display: flex;
        flex-direction: column;
        gap: ${editStyleFormGap};
    }
    .style-container:not(:last-child) {
        border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.three};
        padding-bottom: ${formGap};
    }
`;

const StyledEditStyleHeaderContainer = styled.div<{ show: boolean }>`
    width: 100%;
    .title {
        flex-basis: 95%;
        display: flex;
        align-items: center;
    }
    .preview-header-chevron-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        margin-bottom: ${(props) => `${props.show ? 10 : 0}px`};
    }
    #chevron {
        flex-basis: 5%;
        cursor: pointer;
    }
    .style-properties {
        margin-top: 24px;
    }
`;
