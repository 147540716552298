import { ProjectAttributes } from "@iventis/datafield-editor";
import React from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { toastsDoNothing } from "@iventis/toasts";
import { AssetsSubmenuItemType, AssetsAppComponent } from "./assets-app";
import { CategoryRepositoryComponent } from "./category-repository";
import { IconRepositoryComponent } from "./icon-repository";
import InstancesComponent from "./instances";
import { InstanceSubmenuItemType } from "./instances.types";
import { LayerTemplateRepositoryComponent } from "./layer-templates-repository";
import { MapBackgroundsRepositoryComponent } from "./map-backgrounds-repository";
import { ModelsRepositoryComponent } from "./models-repository";
import NavigationComponent from "./navigation";
import PlanSubscriptionCounts from "./plan-subscription-counts";
import { ProjectImagesRepository } from "./project-images-repository";
import ProjectsTree from "./projects-tree";
import { SitemapRepository } from "./sitemap-repository";
import { SubscriptionPlansComponent } from "./subscription-plans";
import { DigitalTwinComponent } from "./digital-twin";
import { api } from "../api/api";
import DevComponent from "./dev";
import { DevSubmenuItemType } from "./dev.types";
import DevCache from "./dev-cache";

const Router = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <NavigationComponent />,
            children: [
                {
                    path: "/customers",
                    element: <InstancesComponent />,
                    children: [
                        { path: `/customers/${InstanceSubmenuItemType.PROJECT}`, element: <ProjectsTree /> },
                        { path: `/customers/${InstanceSubmenuItemType.PLANSUBSCRIPTIONCOUNTS}`, element: <PlanSubscriptionCounts /> },
                    ],
                },
                {
                    path: "/resources",
                    element: <AssetsAppComponent />,
                    children: [
                        { path: `/resources/${AssetsSubmenuItemType.ICONS}`, element: <IconRepositoryComponent /> },
                        { path: `/resources/${AssetsSubmenuItemType.MODELS}`, element: <ModelsRepositoryComponent /> },
                        { path: `/resources/${AssetsSubmenuItemType.LAYER_TEMPLATES}`, element: <LayerTemplateRepositoryComponent allowEdit /> },
                        {
                            path: `/resources/${AssetsSubmenuItemType.ATTRIBUTES}`,
                            element: (
                                <ProjectAttributes
                                    toast={toastsDoNothing}
                                    projectAttributeApiFunctions={{
                                        get: async () => (await api.post("/data-fields/filter", [])).data,
                                        post: async (dataField) => (await api.post("/data-fields", dataField)).data,
                                        put: async (dataField) => (await api.put(`/data-fields/${dataField.id}`, dataField)).data,
                                    }}
                                />
                            ),
                        },
                        { path: `/resources/${AssetsSubmenuItemType.CATEGORIES}`, element: <CategoryRepositoryComponent /> },
                        { path: `/resources/${AssetsSubmenuItemType.MAP_BACKGROUNDS}`, element: <MapBackgroundsRepositoryComponent /> },
                        { path: `/resources/${AssetsSubmenuItemType.SITEMAPS}`, element: <SitemapRepository /> },
                        { path: `/resources/${AssetsSubmenuItemType.PROJECT_IMAGES}`, element: <ProjectImagesRepository /> },
                    ],
                },
                { path: "/plans", element: <SubscriptionPlansComponent /> },
                { path: "/digital-twin", element: <DigitalTwinComponent /> },
                {
                    path: "/dev",
                    element: <DevComponent />,
                    children: [{ path: `/dev/${DevSubmenuItemType.CACHE}`, element: <DevCache /> }],
                },
            ],
        },
        { path: "*", element: <Navigate to={`/customers/${InstanceSubmenuItemType.PROJECT}`} /> },
    ]);

    return <RouterProvider router={router} />;
};

export default Router;
